@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  background: #1a171f;
  color: #ffffff;
}

body {
  @apply bg-[#1A171F] text-white;
  font-family: 'Inter', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Inter', cursive;
}

a {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s ease 0s;
}

* {
  box-sizing: border-box;
}

.container {
  max-width: 88.75rem;
  margin: 0 auto;
  padding-left: 10px;
  padding-right: 10px;
}

::selection {
  @apply text-white bg-primary;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background: #09351d;
  border-top-right-radius: 100vh;
  border-bottom-right-radius: 100vh;
}

::-webkit-scrollbar-thumb {
  background: #75826d;
  border-radius: 100vh;
}

::-webkit-scrollbar-thumb:hover {
  background: #1eca6f;
}

/* button */
.btn {
  @apply relative z-10 inline-flex border border-transparent justify-center items-center font-semibold transition-all gap-2;
}

.btn-sm {
  @apply px-3 h-7 text-xs;
}
.btn-md {
  @apply px-3 md:px-10 lg:h-[35px] font-medium text-sm;
}
.btn-lg {
  @apply px-3 md:px-5 xl:px-12 h-[45px] font-semibold text-sm;
}
.btn-xl {
  @apply px-3 md:px-5 xl:px-12 h-[55px] font-semibold text-base;
}

.btn-primary {
  @apply bg-gradient-to-r from-primary to-light text-dark;
}

.btn-danger {
  @apply bg-danger border border-solid border-danger hover:bg-[#f31225] text-white;
}

.btn-warning {
  @apply bg-warning border border-solid border-warning hover:bg-[#edb23b] text-white;
}

.btn-success {
  @apply bg-success border border-solid border-success hover:bg-[#11c544] text-white;
}

.btn-default {
  @apply bg-slate-50 !text-slate-500 border border-solid border-slate-200 hover:bg-slate-100;
}

.btn-dark {
  @apply bg-[#2C2A30] text-white border border-[#2C2A30] hover:bg-[#252429];
}

.btn-disabled {
  opacity: 0.6;
}

.btn-outlined {
  @apply !text-white;
  background: transparent !important;
}
.btn-outlined.btn-danger {
  @apply !text-danger;
}

.gradient_border {
  @apply before:absolute before:left-0 before:top-0 before:h-full before:w-[2px] before:bg-gradient-to-br before:from-primary before:to-light after:absolute after:right-0 after:top-0 after:h-full after:w-[2px] after:bg-gradient-to-br after:from-primary after:to-light border-t-[2px] border-t-primary border-b-[2px] border-b-light;
}

.btn-outlined.btn-primary {
  @apply gradient_border;
}

.btn:not(.btn-outlined) {
  @apply overflow-hidden before:absolute before:left-full before:hover:-left-full before:top-0 before:h-full before:w-full before:skew-x-12 before:bg-gradient-to-l before:from-transparent before:via-white before:to-transparent before:duration-500 before:z-[-1];
}

/* custome animations */
.animate-updown {
  animation: updown 2s ease-in-out infinite alternate;
}

@keyframes updown {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-10px);
  }
}

.animate-rollar {
  position: relative;
}
.animate-rollar::after {
  content: '';
  background: linear-gradient(90deg, transparent, white, transparent);
  position: absolute;
  left: 0;
  top: -2px;
  width: 20px;
  height: 5px;
  opacity: 0;
}

.animate-rollar:hover::after {
  animation: rollar 2s ease-in-out infinite;
}

.animate-rollar:hover > .gradient_box {
  transform: rotate(45deg);
}

@keyframes rollar {
  0% {
    left: 0;
    opacity: 0;
  }
  20% {
    left: 1%;
    opacity: 1;
  }
  100% {
    left: calc(100% - 20px);
    opacity: 1;
  }
}

.defaultTypographyApply {
  padding: 100px 15px;
}
@media (max-width: 768px) {
  .defaultTypographyApply {
    padding: 50px 15px;
  }
}
.defaultTypographyApply p,
.defaultTypographyApply ul,
.defaultTypographyApply ol {
  margin-bottom: 30px;
  @apply text-lg md:text-xl lg:text-2xl !leading-[40px] lg:!leading-[50px];
}
.defaultTypographyApply ol {
  margin-left: 23px;
}
.defaultTypographyApply h2 {
  margin-bottom: 30px;
  @apply text-3xl lg:text-4xl;
}
.defaultTypographyApply a {
  @apply text-primary hover:underline no-underline;
}

.card {
  @apply bg-black/30 rounded-[10px] p-[17px];
}

.active_menu {
  @apply !bg-primary !text-dark;
}

/* table css */
table {
  width: 100%;
}

table thead tr td {
  @apply text-secondary text-sm pb-2 px-2;
}

table tbody tr td {
  @apply text-white text-xs font-medium py-4  px-2;
}
table thead tr td:first-child,
table tbody tr td:first-child {
  padding-left: 0;
}
